// Generated by Framer (1d71865)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["yFsVLOhCn"];

const serializationHash = "framer-F7Svd"

const variantClassNames = {yFsVLOhCn: "framer-v-1bg4g3w"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "yFsVLOhCn", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1bg4g3w", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"yFsVLOhCn"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><motion.div className={"framer-mkby29"} layoutDependency={layoutDependency} layoutId={"RlTrBAo1D"} style={{backgroundColor: "rgb(14, 15, 15)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-F7Svd.framer-1kanlpc, .framer-F7Svd .framer-1kanlpc { display: block; }", ".framer-F7Svd.framer-1bg4g3w { height: 200px; position: relative; width: 200px; }", ".framer-F7Svd .framer-mkby29 { flex: none; height: 16px; left: calc(49.50000000000002% - 181px / 2); overflow: visible; position: absolute; top: 11px; width: 181px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerEsRzby8VT: React.ComponentType<Props> = withCSS(Component, css, "framer-F7Svd") as typeof Component;
export default FramerEsRzby8VT;

FramerEsRzby8VT.displayName = "Tabela";

FramerEsRzby8VT.defaultProps = {height: 200, width: 200};

addFonts(FramerEsRzby8VT, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})